const dev = {
  API_ENDPOINT_URL: 'http://localhost:3000',
  SOCKET_URL: 'http://localhost:3000',
};

const prod = {
  API_ENDPOINT_URL: 'https://stgx.sentientiq.com:8000',
  SOCKET_URL: 'https://stgx.sentientiq.com:8000',
};

const stgx = {
  API_ENDPOINT_URL: 'https://stgx.sentientiq.com:8000',
  SOCKET_URL: 'https://stgx.sentientiq.com:8000',
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'stgx':
      return stgx;
    default:
      break;
  }
};

export const env = getEnv();
